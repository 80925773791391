import React, { useState, useEffect } from 'react';

interface Review {
    id: number;
    name: string;
    text: string;
}

export default function Feedback() {
    const reviews: Review[] = [
        {
            id: 1,
            name: "Алина П.",
            text: "Очень довольна сервисом! Понравилось, как быстро и просто я смогла получить займ. Заполнила анкету и в течение часа уже получила одобрение. Рекомендую!"
        },
        {
            id: 2,
            name: "Дмитрий С.",
            text: "Всё прошло гладко. Сначала переживал насчёт своей кредитной истории, но здесь не было никаких проблем. Работают профессионально и быстро!"
        },
        {
            id: 3,
            name: "Елена М.",
            text: "Отличное решение для экстренных финансовых ситуаций! Заполнила анкету, и мне сразу предложили выгодные условия. Спасибо вам!"
        },
        {
            id: 4,
            name: "Игорь Л.",
            text: "Сервис действительно помог в сложный момент. Быстрое оформление и хорошая поддержка. Я остался доволен!"
        },
        {
            id: 4,
            name: "Марина К.",
            text: "Классный сервис! Очень просто и удобно. Брала займ несколько раз, всегда всё на высшем уровне. Спасибо за оперативность!"
        },
        {
            id: 5,
            name: "Сергей Б.",
            text: "Удобно, что можно отслеживать статус заявки в личном кабинете. Не нужно звонить никуда, всё видно. Рекомендую!"
        },
        {
            id: 6,
            name: "Татьяна Н.",
            text: "Никогда раньше не брала микрозаймы, но этот сервис оправдал все ожидания. Всё быстро, понятно и без скрытых условий. Спасибо!"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 1200);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (isWideScreen ? reviews.length - 1 : reviews.length));
        }, 5000);

        return () => clearInterval(timer);
    }, [isWideScreen, reviews.length]);

    const styles: { [key: string]: React.CSSProperties } = {
        heading: {
            fontSize: '32px',
            fontWeight: 'bold',
            marginBottom: '24px',
            textAlign: 'center',
        },
        sliderContainer: {
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
        },
        reviewCard: {
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '24px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            width: isWideScreen ? 'calc(50% - 10px)' : '100%',
            transition: 'opacity 0.5s ease-in-out',
        },
        reviewHeader: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px',
        },
        icon: {
            width: '24px',
            height: '24px',
            marginRight: '12px',
        },
        name: {
            color: '#6366f1',
            fontSize: '18px',
            fontWeight: '500',
        },
        text: {
            fontSize: '16px',
            color: '#1f2937',
            lineHeight: '1.5',
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            marginTop: '24px',
        },
        dot: {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#e5e7eb',
            transition: 'all 0.3s ease',
        },
        activeDot: {
            backgroundColor: '#6366f1',
            width: '24px',
            borderRadius: '4px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.sliderContainer}>
                <div style={styles.reviewCard}>
                    <div style={styles.reviewHeader}>
                        <div style={styles.icon}>
                            <img src={require('../../assets/svg/review.svg').default} alt="review" />
                        </div>
                        <span style={styles.name}>{reviews[currentIndex].name}</span>
                    </div>
                    <p style={styles.text}>{reviews[currentIndex].text}</p>
                </div>
                {isWideScreen && (
                    <div style={styles.reviewCard}>
                        <div style={styles.reviewHeader}>
                            <div style={styles.icon}>
                                <img src={require('../../assets/svg/review.svg').default} alt="review" />
                            </div>
                            <span style={styles.name}>{reviews[(currentIndex + 1) % reviews.length].name}</span>
                        </div>
                        <p style={styles.text}>{reviews[(currentIndex + 1) % reviews.length].text}</p>
                    </div>
                )}
            </div>

            <div style={styles.pagination}>
                {reviews.slice(0, isWideScreen ? reviews.length - 1 : reviews.length).map((_, index) => (
                    <div
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        style={{
                            ...styles.dot,
                            ...(index === currentIndex ? styles.activeDot : {})
                        }}
                    />
                ))}
            </div>
        </div>
    );
}