import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const ShortPage: React.FC<{ utmMedium: string | null }> = ({ utmMedium }) => {
    useEffect(() => {
        utmMedium ? document.body.style.backgroundColor = 'var(--light-gray)' : document.body.style.backgroundColor = '';

        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);
    return (
        <div>
            <Outlet />
        </div>
    );
}

export default ShortPage;