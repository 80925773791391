import React, { useState, useRef, useEffect } from 'react';
import s from './style.module.scss';

interface FAQProps {
    id: number;
    question: string;
    answer: string;
    key: number;
}

const FAQ: React.FC<FAQProps> = ({ id, question, answer }) => {
    const [open, setOpen] = useState<null | number>(null);
    const answerRef = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        setOpen((prevOpen) => (prevOpen === id ? null : id));
    };

    useEffect(() => {
        if (answerRef.current) {
            const height = answerRef.current.scrollHeight;
            answerRef.current.style.setProperty('--answer-height', `${height}px`);
        }
    }, [open]);

    return (
        <div className={s.faq} onClick={handleClick}>
            <div className={s.faq_container}>
                <div className={s.faq_logo}>
                    <img src={require('../../assets/svg/question.svg').default} alt="faq" />
                </div>
                <div className={s.question}>
                    <h1 style={open === id ? { color: 'var(--secondary-text-color)' } : { color: 'var(--primary-color)' }}>{question}</h1>
                    <div
                        ref={answerRef}
                        className={`${s.answer} ${open === id ? s.show : s.hide}`}
                    >
                        <p>{answer}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;

