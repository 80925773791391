import React, { useState } from 'react'
import s from './style.module.scss'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { handleChangePhone } from '../helpers/handleChangePhone'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Errors from '../components/ui/Error/Errors'

const Unsubscribe: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [typeSms, setTypeSms] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const UNSUBSCRIBE = process.env.REACT_APP_UNSIBSCRIBE as string
    const CREATE_VISITOR = process.env.REACT_APP_CREATE_VISITOR as string;
    const SMS_CODE: string = process.env.REACT_APP_SEND_SMS as string;
    const VALIDATE_CODE: string = process.env.REACT_APP_VALIDATE_CODE as string;

    const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);
    const [isSendCode, setIsSendCode] = useState<boolean>(false);

    const pattern = /\D/g;
    const navigate = useNavigate()


    const [verified, setVerified] = useState<boolean>(false);
    const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string

    if (!CAPTCHA) {
        console.error('CAPTCHA not found')
    }

    interface CreateVisitorResponse {
        data: {
            oid: string;
            credit_sum: number;
            credit_days: number;
            fingerprint: {
                ip: string;
                user_agent: string;
            };
        };
        status: string;
    }

    interface SendCodeResponse {
        source: string;
    }

    const getIp = async (): Promise<string> => {
        try {
            const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error(error);
            return '';
        }
    };

    const sendPhoneForAuthorisation = async (phone_number: string) => {
        setIsLoading(true);
        try {
            const ip = await getIp();
            const useragent = navigator.userAgent;

            const createVisitorResponse = await axios.post<CreateVisitorResponse>(CREATE_VISITOR, {
                credit_sum: 0,
                credit_days: 0,
                user_agent: useragent,
                ip: ip,
            });

            if (createVisitorResponse.data.data.oid) {
                const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
                localStorage.setItem('oid', createVisitorResponse.data.data.oid);

                const smsCodeResponse = await axios.post<SendCodeResponse>(SMS_CODE, {
                    phone_number: `${formattedPhoneNumber}`,
                    visitor_oid: createVisitorResponse.data.data.oid,
                });

                if (smsCodeResponse.data && smsCodeResponse.data && smsCodeResponse.data.source) {
                    setTypeSms(smsCodeResponse.data.source);
                    setIsSendCode(true);
                } else {
                    throw new Error('Invalid response structure');
                }
            }
        } catch (error: any) {
            console.log('Error:', error);
            setIsSendCode(false);
            setIsLoading(false);
            if (error.response && error.response.status === 500) {
                setIsError(true);
            } else if (error.response && error.response.status === 404) {
                alert('Ошибка 404: не найдено');
            } else {
                setIsError(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await sendPhoneForAuthorisation(phoneNumber);
        } catch (error) {
            setIsSendCode(false);
        }
    };


    interface ValidateSms {
        phone_number: string;
        jwt: string;
    }
    const handleSendCode = async (e: React.FormEvent<HTMLFormElement>, phone_number: string) => {
        const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
        e.preventDefault();
        try {
            const validateCodeResponse = await axios.post<ValidateSms>(VALIDATE_CODE, {
                phone_number: formattedPhoneNumber,
                code,
            });

            if (validateCodeResponse.status === 200) {
                setIsUnsubscribe(true);
            } else {
                throw new Error(`Ошибка ${validateCodeResponse.status}: ${validateCodeResponse.statusText}`);
            }
        } catch (error) {
            console.log('Error:', error);
            alert('Неверный код');
        }
    };
    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setVerified(true);
        }
    };
    return (
        <>
            {isError ? <Errors text="Пользователь не найден" setter={setIsError} /> : null}
            {
                isUnsubscribe ? (
                    <div className={s.container + ' ' + s.authorisation}>
                        <div className={s.title} style={{ marginTop: '20px' }}>
                            <h1>ВЫ УСПЕШНО ОТКАЗАЛИСЬ ОТ ПОДПИСКИ НА СЕРВИС!</h1>
                        </div>
                    </div>
                ) : (
                    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>
                        <div className={s.container + ' ' + s.authorisation}>
                            <div className={s.title}>
                                <h1>ОТКАЗАТЬСЯ ОТ УСЛУГИ</h1>
                            </div>
                            <div className={s.content}>
                                <form method='post' onSubmit={e => handleGetCode(e)}>
                                    <div className={s.input_field}>
                                        <label htmlFor="login">Мобильный телефон</label>
                                        <input
                                            type="tel"
                                            id="login"
                                            name="login"
                                            value={phoneNumber}
                                            onChange={e => handleChangePhone(e, setPhoneNumber, pattern)}
                                            placeholder="+7 (___) ___ __ __"
                                        />
                                    </div>
                                    <GoogleReCaptcha onVerify={handleRecaptchaChange} />

                                    {
                                        !isSendCode && (
                                            <div className={s.form_btn}>
                                                {isLoading ? (
                                                    <div className="loading"></div>
                                                ) : (
                                                    <input disabled={phoneNumber.length < 18 || verified === false} type="submit" value="Получить код для отписки" style={{ width: '80%' }}/>
                                                )}
                                            </div>
                                        )
                                    }
                                </form>
                                {isSendCode && (
                                    <form method="post" onSubmit={e => handleSendCode(e, phoneNumber)} style={{ marginTop: '-8px' }}>
                                        <p style={{ color: 'var(--primary-color)', marginBottom: '8px' }}>Получить код в {typeSms === "Telegram" ? <a href="https://t.me/VerificationCodes" target="_blank" rel="noreferrer" style={{ color: 'var(--secondary-text-color)' }}>Telegram</a> : "SMS"}</p>
                                        <div className={s.input_field}>
                                            <label htmlFor="code">Код подтверждения</label>
                                            <input
                                                type="number"
                                                id="code"
                                                name="code"
                                                value={code}
                                                onChange={e => setCode(e.target.value)}
                                                placeholder="_ _ _ _"
                                            />
                                        </div>
                                        <div className={s.form_btn}>
                                            {isLoading ? (
                                                <div className="loading"></div>
                                            ) : (
                                                <input
                                                    disabled={!code || code.length < 4 || code.length > 4}
                                                    type="submit"
                                                    value="Отказаться"
                                                    maxLength={4}
                                                />
                                            )}
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </GoogleReCaptchaProvider>
                )
            }
        </>
    )
}

export default Unsubscribe