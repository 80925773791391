export const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>, pattern: RegExp) => {
    let inputNumbersValue = onPhoneInput(e)

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
        let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
        let formattedInputValue = firstSymbols + ' '
        if (inputNumbersValue.length > 1) {
            formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
        }
        if (inputNumbersValue.length >= 5) {
            formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
        }
        if (inputNumbersValue.length >= 8) {
            formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
        }
        if (inputNumbersValue.length >= 10) {
            formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
        }
        setter(formattedInputValue)
    }

    function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
        return e.target.value.replace(pattern, '')
    }
}