import React, { useEffect, useState } from 'react';
import s from './style.module.scss';
import Footer from '../components/layout/Footer/Footer';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import Errors from '../components/ui/Error/Errors';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { handleChangePhone } from '../helpers/handleChangePhone';

const Authorisation: React.FC = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user) as { phone: string };
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone || '');
  const [code, setCode] = useState<string>('');
  const [isSendCode, setIsSendCode] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [typeSms, setTypeSms] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pattern = /\D/g;

  const CREATE_VISITOR = process.env.REACT_APP_CREATE_VISITOR as string;
  const SMS_CODE: string = process.env.REACT_APP_SEND_SMS as string;
  const VALIDATE_CODE: string = process.env.REACT_APP_VALIDATE_CODE as string;
  const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;


  const getIp = async (): Promise<string> => {
    try {
      const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  interface CreateVisitorResponse {
    data: {
      oid: string;
      credit_sum: number;
      credit_days: number;
      fingerprint: {
        ip: string;
        user_agent: string;
      };
    };
    status: string;
  }

  interface SendCodeResponse {
    source: string;
  }

  const sendPhoneForAuthorisation = async (phone_number: string) => {
    setIsLoading(true);
    try {
      const ip = await getIp();
      const useragent = navigator.userAgent;

      const createVisitorResponse = await axios.post<CreateVisitorResponse>(CREATE_VISITOR, {
        credit_sum: 0,
        credit_days: 0,
        user_agent: useragent,
        ip: ip,
      });

      if (createVisitorResponse.data.data.oid) {
        const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
        localStorage.setItem('oid', createVisitorResponse.data.data.oid);

        const smsCodeResponse = await axios.post<SendCodeResponse>(SMS_CODE, {
          phone_number: `${formattedPhoneNumber}`,
          visitor_oid: createVisitorResponse.data.data.oid,
        });

        if (smsCodeResponse.data && smsCodeResponse.data && smsCodeResponse.data.source) {
          setTypeSms(smsCodeResponse.data.source);
          setIsSendCode(true);
        } else {
          throw new Error('Invalid response structure');
        }
      }
    } catch (error: any) {
      console.log('Error:', error);
      setIsSendCode(false);
      setIsLoading(false);
      if (error.response && error.response.status === 500) {
        setIsError(true);
      } else if (error.response && error.response.status === 404) {
        alert('Ошибка 404: не найдено');
      } else {
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await sendPhoneForAuthorisation(phoneNumber);
    } catch (error) {
      setIsSendCode(false);
    }
  };

  interface ValidateSms {
    phone_number: string;
    jwt: string;
  }
  const handleSendCode = async (e: React.FormEvent<HTMLFormElement>, phone_number: string) => {
    const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
    e.preventDefault();
    try {
      const validateCodeResponse = await axios.post<ValidateSms>(VALIDATE_CODE, {
        phone_number: formattedPhoneNumber,
        code,
      });

      if (validateCodeResponse.status === 200) {
        const data = validateCodeResponse.data;
        localStorage.setItem('token', data.jwt);
        window.location.reload();
      } else {
        throw new Error(`Ошибка ${validateCodeResponse.status}: ${validateCodeResponse.statusText}`);
      }
    } catch (error) {
      console.log('Error:', error);
      alert('Неверный код');
    }
  };

  const [verified, setVerified] = useState<boolean>(false);
  const handleRecaptchaChange = (value: string | null) => {
    if (value) {
      setVerified(true);
    }
  };

  useEffect(() => {
    localStorage.getItem('token') && navigate('/personal-account/profile');
    window.scrollTo(0, 0)
  }, [navigate]);

  if (!CAPTCHA) {
    console.error('reCaptcha site key is not defined');
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA} >
      {isError ? <Errors text="Пользователь не найден" link="/contact-information" setter={setIsError} /> : null}
      <div className={s.container + ' ' + s.authorisation}>
        <div className={s.title}>
          <h1>ВХОД В ЛИЧНЫЙ КАБИЕТ</h1>
        </div>
        <div className={s.content}>
          <form method="post" onSubmit={e => handleGetCode(e)}>
            <div className={s.input_field}>
              <label htmlFor="login">Мобильный телефон</label>
              <input
                disabled={isSendCode}
                type="tel"
                id="login"
                name="login"
                value={phoneNumber}
                onChange={e => handleChangePhone(e, setPhoneNumber, pattern)}
                placeholder="+7 (___) ___ __ __"
              />
            </div>
            {!isSendCode && (
              <>
                <GoogleReCaptcha onVerify={handleRecaptchaChange} />
                <div className={s.form_btn}>
                  {isLoading ? (
                    <div className="loading"></div>
                  ) : (
                    <input disabled={phoneNumber.length < 18 || verified === false} type="submit" value="Получить код по SMS" />
                  )}
                </div>
              </>
            )}
          </form>
          {isSendCode && (
            <form method="post" onSubmit={e => handleSendCode(e, phoneNumber)} style={{ marginTop: '-8px' }}>
              <p style={{ color: 'var(--primary-color)', marginBottom: '8px' }}>Получить код в {typeSms === "Telegram" ? <a href="https://t.me/VerificationCodes" target="_blank" rel="noreferrer" style={{ color: 'var(--secondary-text-color)' }}>Telegram</a> : "SMS"}</p>
              <div className={s.input_field}>
                <label htmlFor="code">Код подтверждения</label>
                <input
                  type="number"
                  id="code"
                  name="code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  placeholder="_ _ _ _"
                />
              </div>
              <div className={s.form_btn}>
                {isLoading ? (
                  <div className="loading"></div>
                ) : (
                  <input
                    disabled={!code || code.length < 4 || code.length > 4}
                    type="submit"
                    value="Продолжить"
                    maxLength={4}
                  />
                )}
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </GoogleReCaptchaProvider>
  );
};

export default Authorisation;
