import React from 'react'
import s from './style.module.scss'
import { Link } from 'react-router-dom'

const Documents: React.FC = () => {
    return (
        <div className={s.docs_container}> 
            <div className={s.docs_content}>
                <div className={s.doc}>
                    <p>
                    Услуги предоставляются ИП Фрис Н.И. 
                        (ОГРНИП 320508100359147 ИНН 190119652821, 
                        Реквизиты: Адрес 426063, Удмуртская Респ, Ижевск, ул. Ключевой поселок, д 81 кв 8 
                        Выдача займов у партнеров осуществляется в российских рублях, на банковский счет, наличными или на карту, гражданам Российской Федерации. 
                        Сервис не относится к финансовым учреждениям, не является кредитором или банком и не несет ответственности за заключенные впоследствии клиентами и партерами договоры кредитования. 
                        Доступные суммы займов: от 1 000 рублей до 100 000 рублей. 
                        Срок займа: от 62 дней, при процентной ставке: от 0% до 365% годовых. 
                        Находясь на сайте, а так же совершая любые действия на сайте, 
                        Вы принимаете<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/payment-security-policy-b2p.pdf" target='_blank' rel="noopener noreferrer">условия политики безопасности платежей Best2Pay</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE_%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8_%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%80%D0%B5%D0%BD%D1%82%D0%BD%D1%8B%D1%85_%D0%BF%D0%BB%D0%B0%D1%82%D0%B5%D0%B6%D0%B5%D0%B9.pdf" target='_blank' rel="noopener noreferrer">соглашения о применении рекуррентных платежей</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/payment-security-policy-assist.pdf" target='_blank' rel="noopener noreferrer">политики безопасности Assist</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A8%D0%95%D0%9D%D0%98%D0%95_%D0%BE_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8_%D1%83%D1%87%D0%B5%D1%82%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%B2%D0%BB%D0%B0%D0%B4%D0%B5%D0%BB%D1%8C%D1%86%D0%B0_%D0%B1%D0%B0%D0%BD%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B9_%D0%BA%D0%B0%D1%80%D1%82%D1%8B.pdf" target='_blank' rel="noopener noreferrer">соглашения о хранении учетных данных владельца банковской карты</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%9F%D0%9E%D0%9B%D0%98%D0%A2%D0%98%D0%9A%D0%90_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%B8_%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf" target='_blank' rel="noopener noreferrer">политики обработки персональных данных</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf" target='_blank' rel="noopener noreferrer">договора публичной оферты</a>,<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%94%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D1%83%D1%8E%D1%89%D0%B8%D0%B9_%D1%82%D0%B0%D1%80%D0%B8%D1%84.pdf" target='_blank' rel="noopener noreferrer"> тарифов сервиса</a>, а так же даете<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%90%D0%AF_%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A8%D0%95%D0%9D%D0%98%D0%95_%D0%BE_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85.pdf" target='_blank' rel="noopener noreferrer">согласие обработку персональных данных</a>, и на<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5_%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D1%8B%D1%85_%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D0%BE%D0%B2.pdf" target='_blank' rel="noopener noreferrer">получение рекламных материалов</a>.<span> </span> 
                        Режим работы службы поддержки: 10:00 - 19:00, без выходных. 
                        По любым вопросам вы можете связаться со специалистами сервиса по электронной почте support@vsegda-daem.ru 
                        или по телефону <a href="tel:+7 (495) 940-7355">7 495 940-7355</a>. Стоимость услуги — 1 999,00 RUB. Наличие платной услуги не гарантирует Вам получение займа. 
                        Клиент может отказаться от услуги подбора займов самостоятельно, в любое время — <Link to="/unsubscribe">Отказаться от услуги</Link>.
                    </p>
                </div>
            </div>

            <div className={s.docs_content}>
                <div className={s.doc}>
                    <h1>Наши партнеры</h1>
                    <p>
                        Екапуста ООО МКК «Русинтерфинанс» Лицензия ЦБ РФ: 2120754001243 Адрес: 630055, Новосибирская область, г. Новосибирск, ул. Гнесиных, д. 10/1, оф. 202
                    </p>
                    <p>
                        ЗАЙМЕР ООО МФК «Займер» Лицензия ЦБ РФ: 651303532004088 Адрес: 630099, Новосибирская область, г. Новосибирск, ул. Октябрьская Магистраль, д. 3, оф. 906
                    </p>
                    <p>
                        Money Man ООО МФК "Мани Мен" Лицензия ЦБ РФ: 2110177000478 Адрес: 121096, г. Москва, ул. Василисы Кожиной, д. 1, оф. Д13
                    </p>
                    <p>
                        Zaymigo ООО «Займиго МФК» Лицензия ЦБ РФ: 651303322004222 Адрес: 603093, Нижегородская область, г. Нижний Новгород, ул. Ковровская, д. 21А, пом. 603
                    </p>
                    <p>
                        МигКредит ООО МФК «МигКредит» Лицензия ЦБ РФ: 2110177000037 Адрес: 127018, г. Москва, Сущевский вал, д. 5, стр. 3
                    </p>
                    <p>
                        Webbankir ООО МФК «ВЭББАНКИР» Лицензия ЦБ РФ: 2120177002077 Адрес: 125466, г. Москва, ул. Соколово-Мещерская, д. 29, оф. 308
                    </p>
                    <p>
                        Kviku, ООО МФК “ЭйрЛоанс”, Лицензия ЦБ РФ: 651303045003744. Адрес: г. Москва, 123112 Пресненская наб. 8, стр. 1
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Documents