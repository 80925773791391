import React from 'react'
import s from './style.module.scss'
const NotFoundPage: React.FC = () => {
  return (
    <div className={s.not_found_page}>
      <div className={s.not_found_container}>
        <h2>Кажется, что-то пошло не так...</h2>
        <div className={s.text_error}>
          <h1>4<span>0</span>4</h1>
          <h3>Страницы, которую вы ищете, не сущесвтует.</h3>
        </div>
        <div className={s.get_back} onClick={() => window.history.back()}>
          <button>
            Вернуться
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage