import React, { useEffect, useState } from 'react'
import s from './style.module.scss'
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ContactUsPage: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const pattern = /\D/g;

    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputNumbersValue = onPhoneInput(e)

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
            let formattedInputValue = firstSymbols + ' '
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
            setPhoneNumber(formattedInputValue)
        }

        function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
            return e.target.value.replace(pattern, '')
        }
    }


    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const handleContactUs = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

    }

    const [verified, setVerified] = useState<boolean>(false);
    const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string

    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setVerified(true);
        } else {
            setVerified(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>
                <div className={s.container + ' ' + s.container_contact}>
                    <div className={s.title}>
                        <h1>СВЯЗАТЬСЯ С НАМИ</h1>
                    </div>
                    <div className={s.content}>
                        <form method='post' >
                            <div className={s.input_field}>
                                <label htmlFor="contactName">Имя</label>
                                <input
                                    type="text"
                                    id="contactName"
                                    name="contactName"
                                    placeholder=""
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                            <div className={s.input_field}>
                                <label htmlFor="contactPhone">Номер Телефона</label>
                                <input
                                    type="tel"
                                    id="contactPhone"
                                    name="contactPhone"
                                    placeholder="+7 (___) ___ __ __"
                                    value={phoneNumber}
                                    onChange={handleChangePhone}
                                />
                            </div>
                            <div className={s.input_field}>
                                <label htmlFor="contactEmail">Email</label>
                                <input
                                    type="text"
                                    id="contactEmail"
                                    name="contactEmail"
                                    placeholder=""
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className={s.input_field}>
                                <label htmlFor="contactQuestion">Сообщение</label>
                                <textarea
                                    id="contactQuestion"
                                    name="contactQuestion"
                                    placeholder=""
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                />
                            </div>
                            <GoogleReCaptcha onVerify={handleRecaptchaChange} />
                            <div className={s.form_btn}>
                                <input disabled={!name || !phoneNumber || !email || !message || !verified} type="submit" value="Отправиль" />
                            </div>
                        </form>
                    </div>
                </div>
            </GoogleReCaptchaProvider>
        </>
    )
}

export default ContactUsPage