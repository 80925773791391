import React from 'react'
import s from './style.module.scss'

const Card :React.FC<{img: string, title: string, content: string}> = ({img, title, content}) => {
  return (
    <div className={s.card}>
        <img src={img} alt="icon"/>
        <div className={s.content}>
            <h1 style={ title === '92%' ? {color: 'var(--secondary-text-color)', fontWeight: '700'} : {color: 'var(--primary-color)'}}>{title}</h1>
            <p style={ title === '92%' ? {color: 'var(--primary-color)'} : {color: 'var(--secondary-text-color)', fontWeight: '700'}}>{content}</p>
        </div>
    </div>
  )
}

export default Card