import React from 'react'
import s from './style.module.scss'
import Calculateing from '../../components/layout/Calculateing/Calculateing'

const Header: React.FC<{ utm: boolean }> = ({ utm }) => {
  return (
    <div className={s.header} style={utm ? {marginTop: '-20px'} : {}}>
      <div className={s.calculateing_container}>
        {
          utm ? <Calculateing utm={true} /> : <Calculateing utm={false} />
        }
      </div>
    </div>
  )
}

export default Header