import React from 'react'
import s from './style.module.scss'
const Modal: React.FC<{setter: React.Dispatch<React.SetStateAction<boolean>>}> = ({ setter }) => {
    return (
        <div className={s.wrapper}>
            <div className={s.header}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setter(false)}>
                    <path d="M20 20L11 11M11 11L2 2M11 11L20.0001 2M11 11L2 20.0001" stroke="#CDD9E4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className={s.main}>
                <div className={s.title}>
                    <h1>ОБРАБОТКА <br />ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                </div>
                <div className={s.content}>
                    <p>
                        Даю свое <span> </span> 
                        <a href="https://vsegda-daem.ru/pdfs/%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%90%D0%AF_%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A8%D0%95%D0%9D%D0%98%D0%95_%D0%BE_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85.pdf" target='_blank' rel="noopener noreferrer">согласие на обработку персональных данных</a>,<span> </span>
                        и на <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5_%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D1%8B%D1%85_%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D0%BE%D0%B2.pdf" target='_blank' rel="noopener noreferrer">получение рекламных материалов</a>,<span> </span>
                        принимаю <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf" target='_blank' rel="noopener noreferrer">договора публичной оферты </a>,<span> </span>
                        соглашение <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE_%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8_%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%80%D0%B5%D0%BD%D1%82%D0%BD%D1%8B%D1%85_%D0%BF%D0%BB%D0%B0%D1%82%D0%B5%D0%B6%D0%B5%D0%B9.pdf" target='_blank' rel="noopener noreferrer"> о применении рекуррентных платежей</a>,<span> </span>
                        и<span> </span>
                        <a href="https://vsegda-daem.ru/pdfs/%D0%94%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D1%83%D1%8E%D1%89%D0%B8%D0%B9_%D1%82%D0%B0%D1%80%D0%B8%D1%84.pdf" target='_blank' rel="noopener noreferrer"> тарифов сервиса</a>.
                        Осознаю, что оплата услуг не гарантирует получение займа.
                        Настоящим подтверждаю, что не являюсь получателем единовременных и/или регулярных денежных выплат,
                        предусмотренных Указами Президента РФ.ц
                    </p>
                </div>
                <div className={s.btn}>
                    <button onClick={() => setter(false)}>Далее</button>
                </div>
            </div>
        </div>
    )
}

export default Modal