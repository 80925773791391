import React, { useEffect, useRef, useState } from 'react'
import s from './style.module.scss'
import { Link } from 'react-router-dom'

interface ErrorProps {
    text: string,
    link?: string,
    setter: React.Dispatch<React.SetStateAction<boolean>>
}

const Errors:React.FC<ErrorProps> = ({text, link, setter}) => {
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const errorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
          setTimeout(() => {
            // if (errorRef.current && errorRef.current.parentNode && errorRef.current.parentNode.contains(errorRef.current)) {
            //   errorRef.current.parentNode.removeChild(errorRef.current);
            // }
            setter(false);
          }, 1500);
        }, 1600); 
      
        return () => {
          clearTimeout(timeoutId);
        };
      }, []);

  return (
    <div ref={errorRef} className={s.error_container} style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 1s' }}>
        <h1>{text}</h1>
        {
          link && <Link to={link} style={{textDecoration: 'underline'}}>Регистрация</Link>
        }
    </div>
  )
}

export default Errors