import React from 'react'
import s from '../../page/style.module.scss'

interface PropositionsProps {
    money: string, 
    img: string, 
    title: string, 
    link: string
}

const Propositions: React.FC<PropositionsProps> = ({money, img, title, link}) => {
    return (
        <div className={s.content + ' ' + s.content_proposition} onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}>
            <img src={img} alt={title} />
            <div className={s.content_title + ' ' + s.content_title_proposition } style={{ textAlign: 'center' }}>
                <p style={{ width: '100%', marginBottom: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>{title}</p>
                <h2>{money}</h2>
                <div className={s.get_back} style={{ marginTop: '0' }}>
                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ height: '100%', verticalAlign: 'end' }}>Получить</a>
                </div>
            </div>
        </div>
    )
}

export default Propositions