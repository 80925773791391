import React from 'react'
import s from '../style.module.scss'
import Feed from '../../components/Feed/Feed'

const FeedPage: React.FC = () => {
    return (
        <>
            <div className={s.container}>
                <div className={s.title}>
                    <h1>ЛЕНТА СОБЫТИЙ</h1>
                </div>
                <div className={s.content} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Feed message='Код подтверждения 87779' date='11.09.2024 - 09:01'/>
                    <Feed message='Код подтверждения 87779' date='11.09.2024 - 09:01'/>
                </div>
            </div>
        </>
    )
}

export default FeedPage