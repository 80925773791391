import React from 'react'
import s from './style.module.scss'

const WhyWe:React.FC<{img: string, content: string}> = ({ img, content}) => {
  return (
    <>
        <div className={s.why_we_content_block}>
            <div className={s.why_we_card_img}>
                <img src={img} alt={'locker'} />
            </div>
            <div className={s.why_we_content_text}>
                <p>{content}</p>
            </div>
        </div>
    </>
  )
}

export default WhyWe