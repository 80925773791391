import React from 'react'
import s from './style.module.scss'

const Valets:React.FC = () => {
  return (
    <>
        <div className={s.valets_row}>
            <div className={s.valet}>
              <img src={require("../../assets/img/visa.png")} alt="visa" />
            </div>
            <div className={s.valet}>
              <img src={require("../../assets/img/yoo.png")} alt="yoomoney" />
            </div>
          </div>
          <div className={s.valets_row}>
            <div className={s.valet}>
              <img src={require("../../assets/img/mir.png")} alt="mir" />
            </div>
            <div className={s.valet}>
              <img src={require("../../assets/img/nastercard.png")} alt="mastercard" />
            </div>
          </div>
          <div className={s.valets_row}>
            <div className={s.valet}>
              <img src={require("../../assets/img/qiwi.png")} alt="qiwi" />
            </div>
            <div className={s.valet}>
              <img src={require("../../assets/img/maestro.png")} alt="maestro" />
            </div>
          </div>
    </>
  )
}

export default Valets