import React from 'react'
import s from '../style.module.scss'

const Reports: React.FC = () => {
    return (
        <>
            <div className={s.container}>
                <div className={s.title}>
                    <h1 style={{ marginBottom: '16px' }}>ОТЧЕТЫ</h1>
                    <p>Если вы получили займ или услуги сервиса вам больше не нужны, вы можете остановить обработку заявки самостоятельно и в любое время в разделе 
                        <span> Отказаться от услуги</span> или обратиться в службу поддержки сервиса на горячую линию <span>7 (495) 567-89-01</span> или по почте <span>gengigde@gmail.com.</span></p>
                </div>
                <div className={s.content}>
                    <div className={s.content_title}>
                        <h2>Автоматическая подача заявки на займ в Kviku.</h2>
                        <h2 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}><img src={require('../../assets/svg/bad_response.svg').default} alt="Отказ" style={{ padding: '0', width: '24px', height: '24px' }} />Заявка отклонена.</h2>
                    </div>
                    <div className={s.content_date}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 5.83333V10H14.1667M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z" stroke="var(--gray)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p>11.09.2024 - 09:01</p>
                    </div>
                    <div className={s.dropdown_info} style={{ marginTop: '32px' }}>
                        <div className={s.dropdown_title}>
                            <h3>Отчет по автоматической подаче заявки на займ</h3>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 9L12 16L5 9" stroke="#6C757D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reports 