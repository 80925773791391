import React, { useEffect, useRef, useState } from 'react'
import s from '../style.module.scss'
import Footer from '../../components/layout/Footer/Footer'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { ru } from 'date-fns/locale';
import Modal from '../../components/layout/Modal/Modal'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { handleChangePhone } from '../../helpers/handleChangePhone'

registerLocale('ru', ru);

const ContactInfo: React.FC<{ utm: boolean }> = ({ utm }) => {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [patronymic, setPatronymic] = useState('')
    const [phone, setPhone] = useState('')
    const [birthdate, setBirthdate] = useState<Date | null>(null);
    const [email, setEmail] = useState('')

    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string

    const navigate = useNavigate()
    const oid = useSelector((state: RootState) => state.oid)
    const dispatcher = useDispatch()

    const [know, setKnow] = useState(false)
    const [agreement, setAgreement] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    // Validations
    const pattern = /\D/g;

    const [formattedDate, setFormattedDate] = useState<string>('');
    useEffect(() => {
        if (birthdate) {
            const formatted = birthdate.toISOString().split('T')[0];
            setFormattedDate(formatted);
        }
    }, [birthdate]);

    // Errors
    const [emailError, setEmailError] = useState(false);
    const emailIsValid = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    const [nameError, setNameError] = useState(false);
    const [surnameError, setSurnameError] = useState(false);
    const [patronymicError, setPatronymicError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);

    const [knowError, setKnowError] = useState(false);
    const [agreementError, setAgreementError] = useState(false);

    const handlePointError = () => {
        if (!name) {
            setNameError(true);
        }
        if (!surname) {
            setSurnameError(true);
        }
        if (!patronymic) {
            setPatronymicError(true);
        }
        if (!phone || phone.length < 18) {
            setPhoneError(true);
        }
        if (!birthdate) {
            setBirthdateError(true);
        }
        if (!email) {
            setEmailError(true);
        }
        if (!knowError) {
            setKnowError(true);
        }
        if (!agreementError) {
            setAgreementError(true);
        }
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        if (!emailIsValid(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setEmail(email);
    };

    useEffect(() => {
        (!oid && !localStorage.getItem('oid')) && window.location.replace('/')
    }, [oid])

    // Requests
    const handleAddContactInfo = async () => {
        setIsLoading(true)
        const formattedPhoneNumber = phone.replace(/\D+/g, '').replace(/^8/, '+7');
        await axios.post(PASSPORT_DATA, {
            phone_number: formattedPhoneNumber,
            visitor_oid: oid,
            firstname: name,
            lastname: patronymic,
            middlename: surname,
            birthdate: formattedDate,
            email: email,
        })
            .then(res => {
                setIsLoading(false)
                if (utm) {
                    navigate('/passport?utm_medium=cpa')
                } else navigate('/passport');
            })
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    navigate('/auth');
                } else {
                    setIsLoading(false)
                    console.log(error)
                }
            })
        dispatcher({ type: 'ADD_PHONE', payload: phone })
        dispatcher({ type: 'ADD_NAME', payload: name })

        localStorage.setItem('phone', formattedPhoneNumber)
        localStorage.setItem('name', name)
    }

    const [verified, setVerified] = useState<boolean>(false);
    const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string

    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setVerified(true);
        }
    };

    const [openModal, setOpenModal] = useState<boolean>(false)
    const handleBirthdateChange = (date: Date | null) => {
        if (date) {
            setBirthdateError(false)
        } else {
            setBirthdateError(true)
        }
        setBirthdate(date);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value !== '') {
            setBirthdateError(false);
        } else {
            setBirthdateError(true);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>

                {
                    openModal && <Modal setter={setOpenModal} />
                }
                <div className={s.container + ' ' + s.contact_page} style={{ height: '100%', paddingBottom: '24px', padding: '24px' }}>
                    <div className={s.title}>
                        <h1>КОНТАКТНЫЕ ДАННЫЕ</h1>
                    </div>
                    <div className={s.content}>
                        <form method='post' onSubmit={e => {
                            e.preventDefault()
                            handleAddContactInfo()
                        }}>
                            <div className={s.input_field} style={nameError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="name">Имя</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder=""
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        e.target.value !== '' ? setNameError(false) : setNameError(true)
                                    }}
                                    value={name}
                                    onBlur={
                                        (e) => {
                                            e.target.value !== '' ? setNameError(false) : setNameError(true)
                                        }
                                    }
                                />
                            </div>
                            <div className={s.input_field} style={surnameError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="surname">Фамилия</label>
                                <input
                                    type="text"
                                    id="surname"
                                    name="surname"
                                    placeholder=""
                                    onChange={(e) => {
                                        setSurname(e.target.value)
                                        e.target.value !== '' ? setSurnameError(false) : setSurnameError(true)
                                    }}
                                    value={surname}
                                    onBlur={
                                        (e) => {
                                            e.target.value !== '' ? setSurnameError(false) : setSurnameError(true)
                                        }
                                    }
                                />
                            </div>
                            <div className={s.input_field} style={patronymicError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="patronymic">Отчество</label>
                                <input
                                    type="text"
                                    id="patronymic"
                                    name="patronymic"
                                    placeholder=""
                                    onChange={(e) => {
                                        setPatronymic(e.target.value)
                                        e.target.value !== '' ? setPatronymicError(false) : setPatronymicError(true)
                                    }}
                                    value={patronymic}
                                    onBlur={
                                        (e) => {
                                            e.target.value !== '' ? setPatronymicError(false) : setPatronymicError(true)
                                        }
                                    }
                                />
                            </div>
                            <div className={s.input_field} style={phoneError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="phone">Номер телефона</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder=""
                                    onChange={(e) => {
                                        handleChangePhone(e, setPhone, pattern)
                                        e.target.value.length >= 18 ? setPhoneError(false) : setPhoneError(true)
                                    }}
                                    value={phone}
                                    onBlur={
                                        (e) => {
                                            e.target.value !== '' ? setPhoneError(false) : setPhoneError(true)
                                        }
                                    }
                                />
                            </div>
                            <div className={s.input_field} style={birthdateError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="birthdate">Дата рождения</label>
                                <DatePicker
                                    selected={birthdate}
                                    onChange={handleBirthdateChange}
                                    dateFormat="dd.MM.yyyy"
                                    id="birthdate"
                                    name="birthdate"
                                    locale="ru"
                                    customInput={
                                        <InputMask mask="99.99.9999">
                                            {(inputProps) => <input {...inputProps} />}
                                        </InputMask>
                                    }
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={s.input_field} style={emailError ? { border: '1px solid red' } : {}}>
                                <label htmlFor="email">Электронная почта</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder=""
                                    onChange={(e) => handleEmailChange(e)}
                                    value={email}
                                />
                            </div>
                            <div className={`${s.checkbox_field} ${knowError ? s.error : ''}`}>
                                <input type="checkbox" name='know' id='know' onChange={() => setKnow(!know)} checked={know} />
                                <label htmlFor="know">Я ознакомлен и согласен <span style={{ cursor: 'pointer' }} onClick={() => setOpenModal(!openModal)}>со следующим</span></label>
                            </div>
                            <div className={`${s.checkbox_field} ${agreementError ? s.error : ''}`}>
                                <input type="checkbox" name='agreement' id='agreement' onChange={() => setAgreement(!agreement)} checked={agreement} />
                                <label htmlFor="agreement">Даю согласие на <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE_%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8_%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%80%D0%B5%D0%BD%D1%82%D0%BD%D1%8B%D1%85_%D0%BF%D0%BB%D0%B0%D1%82%D0%B5%D0%B6%D0%B5%D0%B9.pdf"
                                    target='_blank'
                                    rel="noopener noreferrer">рекуррентные списания,
                                </a> <a href="https://vsegda-daem.ru/pdfs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A8%D0%95%D0%9D%D0%98%D0%95_%D0%BE_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8_%D1%83%D1%87%D0%B5%D1%82%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%B2%D0%BB%D0%B0%D0%B4%D0%B5%D0%BB%D1%8C%D1%86%D0%B0_%D0%B1%D0%B0%D0%BD%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B9_%D0%BA%D0%B0%D1%80%D1%82%D1%8B.pdf"
                                    target='_blank'
                                    rel="noopener noreferrer">хранение данных владельца банковской карты.</a> <p>Стоимость услуги - 1 999,00 RUB.</p></label>
                            </div>

                            <GoogleReCaptcha onVerify={handleRecaptchaChange} />
                            <div className={s.form_btn} style={{ marginTop: '8px' }}>
                                {
                                    isLoading
                                        ? <div className="loading" />
                                        : emailError || !knowError || !agreementError || nameError || surnameError || patronymicError || phone.length < 18 ?
                                            <button type="button" onClick={handlePointError}>Продолжить</button>
                                            :
                                            <>
                                                <input disabled={!name || !surname || !patronymic || phone.length < 18 || !birthdate || !email || !know || !agreement || emailError || verified === false} type="submit" value="Продолжить" />
                                            </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {
                    !utm &&
                    <Footer />
                }
            </GoogleReCaptchaProvider>
        </>

    )
}

export default ContactInfo