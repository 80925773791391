import React, { useEffect, useState } from 'react'
import s from '../style.module.scss'
import axios from 'axios';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Error from '../../components/ui/Error/Errors';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { ru } from 'date-fns/locale';

registerLocale('ru', ru);

const PassportDataPage: React.FC<{ utm: boolean }> = ({ utm }) => {
    const [gender, setGender] = useState<string>('');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);
        setErrorFields(false);
    };

    const [passSeries, setPassSeries] = useState<string>('');
    const [passNumber, setPassNumber] = useState<string>('');
    const [codePosition, setCodePosition] = useState<string>('');
    const [dateOfIssue, setDateOfIssue] = useState<Date | null>(null);
    const [birthdayPlace, setBirthPlace,] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState<boolean>(false)

    // const userState = useSelector((state: any) => state.user)
    const navigate = useNavigate()

    const [passportSeriesError, setPassportSeriesError] = useState(false);
    const [passportNumberError, setPassportNumberError] = useState(false);
    const [codePositionError, setCodePositionError] = useState(false);
    const [birthPlaceError, setBirthPlaceError] = useState(false);
    const [dateOfIssueError, setDateOfIssueError] = useState(false);
    const [errorFields, setErrorFields] = useState<boolean>(false);

    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string


    const handleSubmitPassportData = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!gender || passSeries.length < 4 || passNumber.length < 6 || codePosition.length < 6 || !dateOfIssue || !birthdayPlace) {
            setPassportSeriesError(passSeries.length < 4);
            setPassportNumberError(passNumber.length < 6);
            setCodePositionError(codePosition.length < 6);
            setBirthPlaceError(!birthdayPlace);
            setDateOfIssueError(!dateOfIssue);
            setErrorFields(!gender)
        } else {
            setErrorFields(false)
            setIsLoading(true)

            await axios.patch(PASSPORT_DATA, {
                number_phone: localStorage.getItem('phone'),
                birthplace: birthdayPlace,
                gender,
                passport_code: `${passSeries}; ${passNumber}; ${codePosition}; ${dateOfIssue}`,
            }, {
                withCredentials: true
            })
                .then(res => utm ? navigate('/address?utm_medium=cpa') : navigate('/address'))
                .catch(err => {
                    console.log(err)
                    setIsLoading(false)
                    setIsError(true)
                });
        }
    };


    const handleDateChange = (date: Date | null) => {
        setDateOfIssue(date);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value !== '') {
            setDateOfIssueError(false);
        } else {
            setDateOfIssueError(true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className={s.container + ' ' + s.container_contact}>
                {isError ? <Error text="Ошибка в добавлении адресса" setter={setIsError} /> : null}

                <div className={s.title}>
                    <h1>ПАСПОРТНЫЕ ДАННЫЕ</h1>
                </div>
                <div className={s.content}>
                    <form method='post' onSubmit={e => handleSubmitPassportData(e)} >
                        <div className={s.input_field} style={{ border: passportSeriesError ? '1px solid red' : '' }}>
                            <label htmlFor="passportSeries">Серия паспорта</label>
                            <input
                                type="number"
                                id="passportSeries"
                                name="passportSeries"
                                placeholder=""
                                value={passSeries}
                                onChange={(e) => {
                                    if (e.target.value.length > 4) {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                    e.target.value.length > 3 ? setPassportSeriesError(false) : setPassportSeriesError(true)
                                    setPassSeries(e.target.value);
                                }}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setPassportSeriesError(false) : setPassportSeriesError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={{ border: passportNumberError ? '1px solid red' : '' }}>
                            <label htmlFor="passportNumber">Номер паспорта</label>
                            <input
                                type="number"
                                id="passportNumber"
                                name="passportNumber"
                                placeholder=""
                                value={passNumber}
                                onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                    e.target.value.length > 5 ? setPassportNumberError(false) : setPassportNumberError(true)
                                    setPassNumber(e.target.value);
                                }}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setPassportNumberError(false) : setPassportNumberError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={{ border: codePositionError ? '1px solid red' : '' }}>
                            <label htmlFor="codePosition">Код подразделения</label>
                            <input
                                type="number"
                                id="codePosition"
                                name="codePosition"
                                placeholder=""
                                value={codePosition}
                                onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                    e.target.value.length > 5 ? setCodePositionError(false) : setCodePositionError(true)
                                    setCodePosition(e.target.value);
                                }}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setCodePositionError(false) : setCodePositionError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={{ border: dateOfIssueError ? '1px solid red' : ''}}>
                            <label htmlFor="dateOfIssue">Дата выдачи паспорта</label>
                            <DatePicker
                                selected={dateOfIssue}
                                onChange={handleDateChange}
                                dateFormat="dd.MM.yyyy"
                                id="birthdate"
                                name="birthdate"
                                locale="ru"
                                customInput={
                                    <InputMask mask="99.99.9999">
                                        {(inputProps) => <input
                                            {...inputProps}
                                        />}
                                    </InputMask>
                                }
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className={s.input_field} style={{ border: birthPlaceError ? '1px solid red' : '' }}>
                            <label htmlFor="birthPlace">Место рождения</label>
                            <input
                                type="text"
                                id="birthPlace"
                                name="birthPlace"
                                placeholder=""
                                value={birthdayPlace}
                                onChange={(e) => {
                                    setBirthPlace(e.target.value)
                                    e.target.value !== '' ? setBirthPlaceError(false) : setBirthPlaceError(true)
                                }}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setBirthPlaceError(false) : setBirthPlaceError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.checkbox_container}>
                            <div className={s.checkbox_field} style={{ border: errorFields ? '1px solid red' : '', borderRadius: '6px' }}>
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={gender === 'male'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="male">Мужской</label>
                            </div>
                            <div className={s.checkbox_field}  style={{ border: errorFields ? '1px solid red' : '', borderRadius: '6px' }}>
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={gender === 'female'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="female">Женский</label>
                            </div>
                        </div>
                        <div className={s.form_btn}>
                            {
                                isLoading
                                    ? <div className="loading" />
                                    : <input type="submit" value="Продолжить" />
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PassportDataPage