const ADD_OID = "ADD_OID"

const OIDReduser = (state: string | undefined = '', action: { type: string, payload: string }) => {
    switch (action.type) {
      case ADD_OID:
        return action.payload;
      default:
        return state;
    }
  };
  
  export default OIDReduser;