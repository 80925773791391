import React from 'react'

const Feed: React.FC<{ message: string, date: string }> = (message) => {
    return (
        <div style={{
            borderBottom: '1px solid var(--gray)',
            color: 'var(--primary-color)',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingBottom: '16px',
        }}>
            <p>{`Вам была отправлена SMS: “${message.message}”`}</p>
            <div className='time' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.83333V10H14.1667M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z" stroke="var(--gray)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{message.date}</span>
            </div>
        </div>
    )
}

export default Feed